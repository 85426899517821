import axios from 'axios';
import { ENV } from '../utils/env';
import AuthService from './KeycloakApi';

export const getItvSystems = async () => {
  try {
    const token = AuthService.getToken();
    let config = {
      method: 'get',
      url: `${ENV.ELTIS_ADMIN_BACKEND_URL}/itv-system`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const result = await axios(config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
