import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  getOneCompany,
  getCompanySettings,
  editCompanySettings,
  createBucket
} from '../../api/CompanyApi';
import { getAllUsersByOrganizationId } from '../../api/UserApi';

import { URLS } from '../../utils/urls.util';
import { useApi } from '../../hooks/useApi';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/alert.store';

import { getAppSettings } from '../../api/AppSettings';
import { getItvSystems } from '../../api/ItvSystemApi';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { SingleCompanyNavigation } from './SingleCompanyNavigation';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { CompanySettingsValidationConfig } from '../../utils/formValidatorConfigs/config';
import { FormValidator } from '../FormValidator/FormValidator';

export const SingleCompanySettings = ({ id, permission }) => {
  const getOneCompanyApi = useApi(getOneCompany);
  const getCompanySettingsApi = useApi(getCompanySettings);
  const editCompanySettingsApi = useApi(editCompanySettings);
  const createBucketApi = useApi(createBucket);
  const getAppSettingsApi = useApi(getAppSettings);
  const getItvSystemsApi = useApi(getItvSystems);
  const userApi = useApi(getAllUsersByOrganizationId);
  const [company, setCompany] = useState({});
  const [appSettings, setAppSettings] = useState({});
  const [wasChange, setChange] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [validateErrors, setValidateErrors] = useState([]);
  const [settingsData, setSettingsData] = useState({});
  const [isItvAllowed, setItvAllowed] = useState(false);
  const [itvSystems, setItvSystems] = useState([]);
  const [isKeyVisible, setKeyVisible] = useState(false);
  const [isSecretVisible, setSecretVisible] = useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.COMPANIES}>
      Организации
    </Link>,
    <Typography key="3" color="text.primary">
      {company.title}
    </Typography>,
    <Typography key="3" color="text.primary">
      Настройки
    </Typography>,
  ];

  const config = useMemo(() => CompanySettingsValidationConfig(), []);
  const dispatch = useDispatch();

  const toggleKeyVisible = () => {
    setKeyVisible(!isKeyVisible);
  };

  const toggleSecretVisible = () => {
    setSecretVisible(!isSecretVisible);
  };

  const handleMouseDown = (event) => {
      event.preventDefault();
  };

  const handleError = (data) => {
    setValidateErrors(data);
  };

  const canApply = () => {
    return permission.edit && settingsData?.isItvEnabled && !Object.keys(validateErrors).length;
  };

  const canCreateBucket = () => {
    return permission.edit && settingsData?.isItvEnabled && settingsData?.storageBucket
      && settingsData?.storageAccessKeyId && settingsData?.storageSecretAccessKey
      && settingsData?.storagePath && settingsData?.storageHost;
  };

  const updateSettings = () => {
    editCompanySettingsApi.sendRequest(settingsData)
      .then(() =>
        dispatch(
          setAlert({
            text: 'Настройки организации обновлены.',
            status: 200,
          }),
        ),
      )
      .catch((err) => console.error(err));
  };

  const postBucket = () => {
    const request = {
      companyId: settingsData.companyId,
      bucketName: settingsData.storageBucket,
      accessKeyId: settingsData.storageAccessKeyId,
      secretAccessKey: settingsData.storageSecretAccessKey,
      path: settingsData.storagePath,
      host: settingsData.storageHost,
    };
    createBucketApi.sendRequest(request)
      .then((response) => {
        if (response.success) {
          setSettingsData({
            ...settingsData,
            storageBucketEndpoint: response.data.bucketEndpoint,
          });
          dispatch(
            setAlert({
              text: 'Бакет успешно создан.',
              status: 200,
            }),
          );
        } else {
          dispatch(
            setAlert({
              text: response.error,
              status: 409,
            }),
          );
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getAppSettingsApi.sendRequest().then((result) => setAppSettings(result));
    getItvSystemsApi.sendRequest()
      .then((result) => setItvSystems(result.items))
      .catch((err) => console.error(err));
    if (id === 'create') {
      return;
    }
    getOneCompanyApi.sendRequest(id, ['meta']).then((company) => {
      setCompany(company);
      getCompanySettingsApi.sendRequest(id, ['meta']).then((s) => {
        setSettingsData({
          id: s.id,
          companyId: s.companyId,
          isItvEnabled: s.isItvEnabled || false,
          maxCameraCount: s.maxCameraCount || 1,
          itvSystemId: s.itvSystemId || null,
          volumeLabel: s.volumeLabel || '',
          volumeFormat: s.volumeFormat,
          volumeSize: s.volumeSize || 0,
          volumeAutoMount: s.volumeAutoMount || true,
          storageBucket: s.storageBucket || '',
          storageRegion: s.storageRegion || '',
          storageAccessKeyId: s.storageAccessKeyId || '',
          storageSecretAccessKey: s.storageSecretAccessKey || '',
          storagePath: s.storagePath || '',
          storageHost: s.storageHost || '',
          storageBucketEndpoint: s.storageBucketEndpoint || ''
        });
      })
      .catch((err) => console.error(err));

      userApi.sendRequest(
        10,
        0,
        {
          q: `organizationId:${company.id}`,
        },
        ['/OrganizationAdministrator'],
      )
      .then(([result, count]) => {
        const employeeCount = result?.filter(
          (e) => e.enabled === true,
        )?.length;
        setItvAllowed(employeeCount > 0);
      })
      .catch((err) => console.error(err));
    });
  }, [id]);

  return (
    <FullPageLayout
      obj={company}
      seoTitle={'Настройки - ' + company?.title}
      pageTitle={company.title}
      isCreated={false}
      breadcrumbs={breadcrumbs}
      showHiddenData={true}
    >
      <SingleCompanyNavigation
        company={company}
        activeTab={6}
        wasChange={wasChange}
        appSettings={appSettings}
      />
      <FormValidator
        name="company-settings-form-name"
        validationConfig={config}
        changed={settingsData}
        setErrorsHandler={(data) => handleError(data)}
      >
        <Typography sx={{ mt: 3 }} variant="h5">
          Настройки
        </Typography>
        {!!Object.keys(settingsData)?.length && (
          <FormControlLabel
            sx={{ mt: 3 }}
            control={
              <Switch
                checked={settingsData?.isItvEnabled}
                defaultChecked={settingsData?.isItvEnabled}
                disabled={!permission.edit || !isItvAllowed}
                onChange={({ target }) => {
                  if (!target.checked) {
                    setOpenApprove(true);
                  } else {
                    setSettingsData({
                      ...settingsData,
                      isItvEnabled: target.checked,
                    });
                  }
                }}
              />
            }
            label="Использование системы ITV"
          />
        )}
        {!isItvAllowed && (
          <InputLabel>
            Использование системы ITV недоступно, так как в организации нет ни одного администратора.
          </InputLabel>
        )}
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
          <TextField
            label="Максимальное количество камер"
            sx={{ width: '34%' }}
            type="number"
            name="maxCameraCount"
            onWheel={(e) => e.target.blur()}
            disabled={!permission.edit || !settingsData.isItvEnabled}
            InputLabelProps={{
              shrink: true,
            }}
            required={config.maxCameraCount.isRequired}
            value={settingsData?.maxCameraCount?.toString() || 0}
            error={validateErrors?.maxCameraCount?.text}
            helperText={validateErrors?.maxCameraCount?.text}
            onChange={({ target: { value } }) =>
              setSettingsData({ ...settingsData, maxCameraCount: +value || 0 })
            }
          />
          <FormControl
            fullWidth
            required={config.itvSystemId.isRequired}
          >
            <InputLabel id="itv-system-label">Система ITV</InputLabel>
            <Select
              id="itv-system-select"
              labelId="itv-system-label"
              InputLabelProps={{
                shrink: true,
              }}
              name="itvSystemId"
              disabled={!permission.edit || !settingsData.isItvEnabled}
              required={config.itvSystemId.isRequired}
              value={settingsData?.itvSystemId?.toString() || ''}
              label="Система ITV"
              onChange={({ target: { value } }) =>
                setSettingsData({ ...settingsData, itvSystemId: value })
              }
            >
              {itvSystems.map((s) => (
                  <MenuItem value={s.id}>{s.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Typography as="p" variant="h5" sx={{ mt: 4, mb: 3 }}>
          Настройки архива
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
          <TextField
            label="Метка тома"
            sx={{ width: '25%' }}
            type="text"
            name="volumeLabel"
            onWheel={(e) => e.target.blur()}
            disabled={!permission.edit || !settingsData.isItvEnabled}
            InputLabelProps={{
              shrink: true,
            }}
            required={config.volumeLabel.isRequired}
            value={settingsData?.volumeLabel}
            error={validateErrors?.volumeLabel?.text}
            helperText={validateErrors?.volumeLabel?.text}
            onChange={({ target: { value } }) =>
              setSettingsData({ ...settingsData, volumeLabel: value })
            }
          />
          <FormControlLabel
            sx={{ mt: 3 }}
            control={
              <Switch
                checked={settingsData?.volumeFormat}
                defaultChecked={settingsData?.volumeFormat}
                disabled={!permission.edit || !settingsData.isItvEnabled}
                onChange={({ target }) => {
                  setSettingsData({
                    ...settingsData,
                    volumeFormat: target.checked,
                  });
                }}
              />
            }
            label="Форматировать созданный том"
          />
          <TextField
            label="Размер тома в байтах"
            sx={{ width: '21%' }}
            type="number"
            name="volumeSize"
            onWheel={(e) => e.target.blur()}
            disabled={!permission.edit || !settingsData.isItvEnabled || !settingsData?.volumeFormat}
            InputLabelProps={{
              shrink: true,
            }}
            required={config.volumeSize.isRequired && settingsData?.volumeFormat}
            value={settingsData?.volumeSize}
            error={validateErrors?.volumeSize?.text}
            helperText={validateErrors?.volumeSize?.text}
            onChange={({ target: { value } }) =>
              setSettingsData({ ...settingsData, volumeSize: +value || 0 })
            }
          />
          <FormControlLabel
            sx={{ mt: 3 }}
            control={
              <Switch
                checked={settingsData?.volumeAutoMount}
                defaultChecked={settingsData?.volumeAutoMount}
                disabled={!permission.edit || !settingsData.isItvEnabled}
                onChange={({ target }) => {
                  setSettingsData({
                    ...settingsData,
                    volumeAutoMount: target.checked,
                  });
                }}
              />
            }
            label="Монтировать созданный том"
          />
        </Stack>
        <Typography as="p" variant="h5" sx={{ mt: 4, mb: 3 }}>
          Настройки хранилища
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
          <TextField
            label="Имя тома архива"
            sx={{ width: '50%' }}
            type="text"
            name="storageBucket"
            onWheel={(e) => e.target.blur()}
            disabled={!permission.edit || !settingsData.isItvEnabled}
            InputLabelProps={{
              shrink: true,
            }}
            required={config.storageBucket.isRequired}
            value={settingsData?.storageBucket}
            error={validateErrors?.storageBucket?.text}
            helperText={validateErrors?.storageBucket?.text}
            onChange={({ target: { value } }) =>
              setSettingsData({ ...settingsData, storageBucket: value })
            }
          />
          <TextField
            label="Регион нахождения тома"
            sx={{ width: '50%' }}
            type="text"
            name="storageRegion"
            onWheel={(e) => e.target.blur()}
            disabled={!permission.edit || !settingsData.isItvEnabled}
            InputLabelProps={{
              shrink: true,
            }}
            required={config.storageRegion.isRequired}
            value={settingsData?.storageRegion}
            error={validateErrors?.storageRegion?.text}
            helperText={validateErrors?.storageRegion?.text}
            onChange={({ target: { value } }) =>
              setSettingsData({ ...settingsData, storageRegion: value })
            }
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
          <FormControl variant="outlined" sx={{ width: '50%' }}>
            <InputLabel htmlFor="storageAccessKeyId" shrink="true">
            Идентификатор ключа доступа
            </InputLabel>
            <OutlinedInput
              id="storageAccessKeyId"
              label="Идентификатор ключа доступа"
              notched="true"
              type={isSecretVisible ? 'text' : 'password'}
              name="storageAccessKeyId"
              onWheel={(e) => e.target.blur()}
              disabled={!permission.edit || !settingsData.isItvEnabled}
              required={config.storageAccessKeyId.isRequired}
              value={settingsData?.storageAccessKeyId}
              error={validateErrors?.storageAccessKeyId?.text}
              helperText={validateErrors?.storageAccessKeyId?.text}
              onChange={({ target: { value } }) =>
                setSettingsData({ ...settingsData, storageAccessKeyId: value })
              }
              endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        onClick={toggleSecretVisible}
                        onMouseDown={handleMouseDown}
                    >
                        {isSecretVisible ? (
                            <Visibility />
                        ) : (
                            <VisibilityOff />
                        )}
                    </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl variant="outlined" sx={{ width: '50%' }}>
            <InputLabel htmlFor="storageSecretAccessKey" shrink="true">
              Пароль ключа доступа
            </InputLabel>
            <OutlinedInput
              id="storageSecretAccessKey"
              label="Пароль ключа доступа"
              notched="true"
              type={isKeyVisible ? 'text' : 'password'}
              name="storageSecretAccessKey"
              onWheel={(e) => e.target.blur()}
              disabled={!permission.edit || !settingsData.isItvEnabled}
              required={config.storageSecretAccessKey.isRequired}
              value={settingsData?.storageSecretAccessKey}
              error={validateErrors?.storageSecretAccessKey?.text}
              helperText={validateErrors?.storageSecretAccessKey?.text}
              onChange={({ target: { value } }) =>
                setSettingsData({ ...settingsData, storageSecretAccessKey: value })
              }
              endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        onClick={toggleKeyVisible}
                        onMouseDown={handleMouseDown}
                    >
                        {isKeyVisible ? (
                            <Visibility />
                        ) : (
                            <VisibilityOff />
                        )}
                    </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
          <TextField
            label="Расположение папки тома Интеллект X внутри bucket"
            sx={{ width: '50%' }}
            type="text"
            name="storagePath"
            onWheel={(e) => e.target.blur()}
            disabled={!permission.edit || !settingsData.isItvEnabled}
            InputLabelProps={{
              shrink: true,
            }}
            required={config.storagePath.isRequired}
            value={settingsData?.storagePath}
            error={validateErrors?.storagePath?.text}
            helperText={validateErrors?.storagePath?.text}
            onChange={({ target: { value } }) =>
              setSettingsData({ ...settingsData, storagePath: value })
            }
          />
          <TextField
            label="Адрес сервера"
            sx={{ width: '50%' }}
            type="text"
            name="storageHost"
            onWheel={(e) => e.target.blur()}
            disabled={!permission.edit || !settingsData.isItvEnabled}
            InputLabelProps={{
              shrink: true,
            }}
            required={config.storageHost.isRequired}
            value={settingsData?.storageHost}
            error={validateErrors?.storageHost?.text}
            helperText={validateErrors?.storageHost?.text}
            onChange={({ target: { value } }) =>
              setSettingsData({ ...settingsData, storageHost: value })
            }
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
          <TextField
            label="Полный путь до bucket endpoint"
            sx={{ width: '100%' }}
            type="text"
            name="storageBucketEndpoint"
            onWheel={(e) => e.target.blur()}
            disabled={!permission.edit || !settingsData.isItvEnabled}
            InputLabelProps={{
              shrink: true,
            }}
            required={config.storageBucketEndpoint.isRequired}
            value={settingsData?.storageBucketEndpoint}
            error={validateErrors?.storageBucketEndpoint?.text}
            helperText={validateErrors?.storageBucketEndpoint?.text}
            onChange={({ target: { value } }) =>
              setSettingsData({ ...settingsData, storageBucketEndpoint: value })
            }
          />
        </Stack>
        <Stack direction="row" spacing={2} sx={{ mt: 4, flexDirection: 'column', alignItems: 'flex-end' }}>
          <div>
          <Button
            variant="outlined"
            sx={{ width: '160px', marginRight: '16px' }}
            disabled={!canCreateBucket()}
            onClick={postBucket}
          >
            Создать бакет
          </Button>
          <Button
            variant="outlined"
            sx={{ width: '140px' }}
            disabled={!canApply()}
            onClick={updateSettings}
          >
            Применить
          </Button>
          </div>
        </Stack>
      </FormValidator>

      <ActionApproveV2
          open={openApprove}
          title="Вы действительно хотите отключить использование системы ITV для этой организации?"
          handleClose={() => setOpenApprove(false)}
          showDescription={false}
          handleSuccess={() => {
            setSettingsData({
              ...settingsData,
              isItvEnabled: false
            });
            editCompanySettingsApi.sendRequest({
              ...settingsData,
              isItvEnabled: false
            })
              .then(() =>
                dispatch(
                  setAlert({
                    text: 'Настройки организации обновлены.',
                    status: 200,
                  }),
                ),
              )
              .catch((err) => console.error(err));
            setOpenApprove(false);
          }}
          actionButtonText="Да"
          handleCloseText="Нет"
        />
    </FullPageLayout>
  );
};
